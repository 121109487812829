import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../layouts/layout"
import SEO from "../components/seo"

import Container from "@material-ui/core/Container"
import RelatedArticles from "../components/blog/blog-related-articles"
import Chip from "@material-ui/core/Chip"
import { BlogStyled } from "./styles"
import HeroTemplate from "../components/hero/hero-template"
import Img from "gatsby-image"

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const { previous, next } = this.props.pageContext
    const { cover } = post.frontmatter
    const coverPath = cover && cover.children[0].fluid.src

    return (
      <Layout>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
          image={coverPath}
        />
        <HeroTemplate
          background={
            <Img
              fluid={cover.children[0].fluid}
              style={{ position: "static", width: "100%", height: "100%" }}
            />
          }
        >
          <p style={{ color: "#8f8f8f" }}>{post.frontmatter.date}</p>
          <h1>{post.frontmatter.title}</h1>
        </HeroTemplate>
        <Container maxWidth="md">
          {post.frontmatter.tags.map(item => {
            return (
              <Link to={`/blog/tags/${item}`} key={item}>
                <Chip
                  size="small"
                  clickable
                  label={item}
                  style={{ margin: 5, marginBottom: 15, marginTop: 15 }}
                />
              </Link>
            )
          })}
          <BlogStyled
            dangerouslySetInnerHTML={{
              __html: post.html,
            }}
          />
          <RelatedArticles next={next} previous={previous} />
        </Container>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        tags
        date(formatString: "MMMM DD, YYYY")
        description
        cover {
          children {
            ... on ImageSharp {
              fluid(maxWidth: 1200, maxHeight: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
