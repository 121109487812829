import styled from "styled-components"
import React from "react"

const Hero = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-top: 60px;
  color: white;
  text-shadow: black 2px 2px;
  min-height: 400px;

  @media (min-width: 992px) {
    max-height: 500px;
  }

  @media (min-width: 768px) {
    min-height: 450px;
  }
`

const HeroOverlay = styled.div`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(27, 28, 32, 0.75);
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.3) 0,
    rgba(0, 0, 0, 0.9) 100%
  );
`

const HeroTextContainer = styled.div`
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 3rem;
  padding-bottom: 3rem;
  position: absolute;
  bottom: 0;
  left: 50%;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 100%;
  max-width: 650px;
`

const HeroTemplate = props => {
  return (
    <Hero>
      {props.background}
      <HeroOverlay />
      <HeroTextContainer>{props.children}</HeroTextContainer>
    </Hero>
  )
}

export default HeroTemplate
