import styled from "styled-components"

const BlogStyled = styled.div`
  font-family: "Fira Sans";
  padding-top: 2em;
  padding-bottom: 2em;

  blockquote {
    margin-left: -1.75rem;
    margin-right: 1.75rem;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 1.42188rem;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.75rem;
    font-size: 1.20112rem;
    line-height: 1.75rem;
    color: hsla(0, 0%, 0%, 0.59);
    font-style: italic;
    border-left: 0.32813rem solid hsla(0, 0%, 0%, 0.9);
    hyphens: auto;
    word-break: break-word;
  }

  li {
    display: list-item;
    text-align: -webkit-match-parent;
  }

  ul {
    margin-left: 1.75rem;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.75rem;
    list-style-position: outside;
    list-style-image: none;
    list-style: disc;
    font-size: 1rem;
  }
`

export { BlogStyled }
