import React from "react"
import styled from "styled-components"
import Grid from "@material-ui/core/Grid"
import Chip from "@material-ui/core/Chip"
import { Link } from "gatsby"

import ArrowRight from "../../../static/arrow/arrowright.svg"
import ArrowLeft from "../../../static/arrow/arrowleft.svg"

import { navigate } from "@reach/router"

const BlogItemTextContainer = styled.div`
  border-radius: 3px;
  background: #fff;
  box-shadow: 0px 3px 22px rgba(54, 81, 255, 0.16);
  margin: 15px;
  margin-bottom: 30px;
  position: relative;
  height: 100%;

  &:hover {
    box-shadow: 3px 10px 50px rgba(54, 81, 255, 0.36);
    cursor: pointer;
  }
`

const BlogItemText = styled.div`
  font-family: "Fira Sans";
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0.1em;
  line-height: 15px;
  text-align: left;
  color: #000;
  opacity: 0.7;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
`

const BlogImg = styled.div`
  width: 52px;
  height: 57px;
  border-radius: 3px;
  background: #8b93bd;
  filter: 5px;
  position: relative;
  top: -15px;
  left: 5px;
  display: flex;
  align-items: center;
`

const BlogItemMobile = props => {
  const ArrowContainer = styled.div`
    width: 35px;
    height: 35px;
    background: linear-gradient(#1e4778 0%, #09366b 100%);
    border-radius: 10px;
    position: absolute;
    bottom: -10px;
    right: 0;
    left: ${props => (props.left !== undefined ? props.left : "auto")};
    display: flex;
    justify-content: center;
    alignitems: center;
  `

  const ArrowImage = styled.img`
    height: 15px;
    width: 20px;
    position: relative;
    top: 30%;
    left: 7%;

    &:hover {
      animation: pulse 1s infinite;
      animation-timing-function: linear;
    }

    @keyframes pulse {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(0.8);
      }
      75% {
        transform: scale(1.1);
      }
      100% {
        transform: scale(1);
      }
    }
  `

  const navigateBlog = () => {
    navigate(props.post.fields.slug)
  }

  return (
    <BlogItemTextContainer onClick={navigateBlog}>
      <Grid container justify="space-between">
        <Grid item xs={2}>
          <BlogImg>
            <div style={{ width: 52 }}>{props.img}</div>
          </BlogImg>
        </Grid>
        <Grid item xs={9}>
          <h3 style={{ marginTop: 2, marginBottom: 2 }}>
            {props.post.frontmatter.title}
          </h3>
          <p>{props.post.frontmatter.date}</p>
          {props.post.frontmatter.tags.map(item => {
            return (
              <Link to={`/blog/tags/${item}`} key={item}>
                <Chip
                  size="small"
                  clickable
                  label={item}
                  style={{ margin: 5 }}
                />
              </Link>
            )
          })}
          <BlogItemText>{props.post.excerpt}</BlogItemText>
        </Grid>
      </Grid>
      <ArrowContainer left={props.left}>
        {props.left !== undefined ? (
          <ArrowImage src={ArrowLeft} alt="arrow left" />
        ) : (
          <ArrowImage src={ArrowRight} alt="arrow right" />
        )}
      </ArrowContainer>
    </BlogItemTextContainer>
  )
}

export default BlogItemMobile
