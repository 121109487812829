import React from "react"
import Grid from "@material-ui/core/Grid"
import { Link } from "gatsby"
import BlogItemMobile from "./blog-item-mobile"
import Img from "gatsby-image"

const RelatedArticles = props => {
  return (
    <Grid container justify="center">
      <Grid item xs={12}>
        <h4>Related Articles:</h4>
      </Grid>
      <Grid item sm={6}>
        {props.previous && (
          <Link to={props.previous.fields.slug} rel="previous">
            <BlogItemMobile
              post={props.previous}
              left={0}
              img={
                <Img
                  style={{ width: 52, height: 52 }}
                  fluid={props.previous.frontmatter.cover.childImageSharp.fluid}
                />
              }
            />
          </Link>
        )}
      </Grid>
      <Grid item sm={6}>
        {props.next && (
          <Link to={props.next.fields.slug} rel="next">
            <BlogItemMobile
              post={props.next}
              img={
                <Img
                  style={{ width: 52, height: 52 }}
                  fluid={props.next.frontmatter.cover.childImageSharp.fluid}
                />
              }
            />
          </Link>
        )}
      </Grid>
    </Grid>
  )
}

export default RelatedArticles
